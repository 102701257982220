<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
      <!-- <div class="flex justify-between items-center"> -->
        <h1 class="heading-2 text-center">{{parseInt(this.$route.query.invoiceId) > 0 ? 'Edit Invoice' : 'Create Invoice'}}</h1>
        <span class=" pl-4" v-if="visitDetail !== null">
          ( Visit # {{visitDetail.visitNumber}} Of {{visitDetail.jobTitle}} )
        </span>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
        <div class=" card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
    <!-- <div class="grid grid-cols-12" >
        <div class=" col-span-6 card rounded-xl bg-white p-4 my-4"> -->
            <div>
                <div class="sm:grid grid-cols-2 gap-4">
                    <div class="mb-4 sm:mb-0">
                        <TextField :placholderText="`Invoice Date`" :setReadOnly="true" :autoFocus="false" :inputext="invObj.invoiceDate" :showDate="true" :lableText="'Invoice Date'" :inputId="'Invoicedkid'" @onClickInputAction="invoiceDatePickShow = true" />
                        <div v-if="invoiceDatePickShow" class="popup_overlay">
                            <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                                <DateTimePickerCustom
                                    v-model="invObj.invoiceDate"
                                    v-if="invoiceDatePickShow"
                                    :showDate="true"
                                    :title="'Invoice Date'"
                                    :showOnlyDate="true"
                                    :showStartDate="true"
                                    :projectSection="true"
                                    @input="updateDate()"
                                    @closeDateTimepickerDialog="invoiceDatePickShow = false">
                                </DateTimePickerCustom>
                            </div>
                        </div>
                        <p v-if="errObj.dateError !== ''" class="text-error heading-7">{{errObj.dateError}}</p>
                    </div>
                    <div class="mb-4 sm:mb-0">
                        <TextField :placholderText="`Due Date`" :setReadOnly="true" :autoFocus="false" :inputext="invObj.dueDate" :showDate="true" :lableText="'Due Date'" :inputId="'invduedateid'" @onClickInputAction="invoiceDueDatePickShow = true" />
                        <div v-if="invoiceDueDatePickShow" class="popup_overlay">
                            <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                                <DateTimePickerCustom
                                    v-model="invObj.dueDate"
                                    v-if="invoiceDueDatePickShow"
                                    :showDate="true"
                                    :title="'Due Date'"
                                    :showOnlyDate="true"
                                    :showStartDate="true"
                                    :min="invObj.invoiceDate"
                                    @input="updateDate()"
                                    :minDate="invObj.invoiceDate"
                                    :projectSection="true"
                                    @closeDateTimepickerDialog="invoiceDueDatePickShow = false">
                                </DateTimePickerCustom>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <Dropdown 
                        :disabled="isDisable"
                        :inputext="searchForCustomer" 
                        :inputId="'searchwp-Customer'"
                        :lableText="'Customer'"
                        :placholderText="`Customer`"
                        :autoFocus="false"
                        :showArrow="true"
                        :setReadOnly="false"
                        :searchCallApi="true"
                        :fieldError="errObj.custErr !== ''"
                        :showPlus="true"
                        :keyName="'companyName'"
                        :listId="'customerlistid'"
                        :items="customersList"
                        @enterkeyPressAction="errObj.custErr =''"
                        @inputChangeAction="callsearchCust"
                        @addNew="addNewCustomer"
                        @selectNewForVal="selectNewCustomer"
                        />
                        <p v-if="errObj.custErr !== ''" class="text-error heading-7">{{errObj.custErr}}</p>
                    </div>
                    <div v-if="invObj.customerId > 0" class="">
                      <Dropdown 
                        :inputext="invObj.billingFulladd" 
                        :inputId="'billingAddressid'"
                        :lableText="'Billing Address'"
                        :placholderText="`Billing Address`"
                        :autoFocus="false"
                        :showArrow="true"
                        :setReadOnly="true"
                        :searchCallApi="false"
                        :showPlus="false"
                        :keyName="'fullAddress'"
                        :listId="'fullAddressidlits'"
                        :items="addressList"
                        @selectNewForVal="selectBilAddress"
                        />
                    </div>
                    <div v-if="invObj.customerId > 0" class="">
                      <Dropdown 
                        :inputext="invObj.shippingFulladd" 
                        :inputId="'shippingAddressid'"
                        :lableText="'Shipping Address'"
                        :placholderText="`Shipping Address`"
                        :autoFocus="false"
                        :showArrow="true"
                        :setReadOnly="true"
                        :searchCallApi="false"
                        :showPlus="false"
                        :keyName="'fullAddress'"
                        :listId="'fzullAddressidlits'"
                        :items="addressList"
                        @selectNewForVal="selectShipAddress"
                        />
                    </div>
                </div>
                <div>
                  <div  class="mt-2" v-if="invObj.selectedItemList.length > 0">
                     <div class="mb-4">
                      <p class="text-gray4 heading-4 font-bold">Items list:</p>
                      <p v-if="requestFrom === 'job'" class="text-error heading-7">After creating this invoice, line items from job will be cleared.</p>
                     </div>
                     
                     <div class="px-4 cursor-pointer hover:bg-gray1 border-b py-1" v-for="(data, index) in invObj.selectedItemList" :key="index + 'key'" @click="showitemDetail(data)">
                        <div class="text-text2 text-sm flex mb-1 font-bold justify-between">
                            <p class="pr-4" >{{data.itemName}}</p>
                            <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
                        </div>
                        <div class="text-text2 flex">
                            <p class="w-32 sm:w-72 heading-7">{{data.itemPrice | amountOnlyNumber}}</p>
                            <span class="w-32 sm:w-72 heading-7">Qty:{{data.itemQty}} <span class="pl-4" v-if="data.isTaxable">Taxed</span></span>
                        </div>
                        <!-- <div class="text-text2 flex">
                            <p class="heading-7 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
                        </div> -->
                     </div>
                  </div>
                   <div v-if="requestFrom === ''" class="mt-2 py-2 heading-5">
                        <span  v-if="invObj.selectedItemList.length > 0" @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add more Item</span>
                        <span v-else  @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add Item to this invoice</span>
                      <p v-if="errObj.itemErr !== ''" class="text-error heading-7">{{errObj.itemErr}}</p>
                    </div>
                  <div  class="" v-if="invObj.selectedItemList.length > 0">
                    <div class="flex justify-between py-2 px-4">
                        <p class="text-text2 font-bold w-full text-right">Items Total (A):</p>
                        <p class="text-text2 font-bold w-full text-right">{{invObj.subTotal.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                   <div class="divider mt-2 mb-4"></div>
                    <div v-for="(taxes, index) in finalTaxList" :key="index + 'in'" class=" border-b py-2 heading-6">
                        <div class="px-4">
                            <div class="flex justify-between w-full sm:justify-start sm:w-8/12">
                                <div class="text-gray4  sm:w-32">{{taxes.taxName.substr(0, 20)}} <span v-if="taxes.taxName.length > 20">...</span>
                                <p class="text-gray4 flex sm:hidden sm:w-32">({{taxes.taxValue.toFixed(3)}} %)</p>
                                </div>
                                <p class="text-gray4 hidden sm:flex w-20 sm:w-32">({{taxes.taxValue.toFixed(3)}} %)</p>
                                <p class="text-gray4 sm:w-32 text-right">{{taxes.ammount.toFixed(2) | amountOnlyNumber}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between py-2 mt-2 px-4">
                        <p class="text-text2 font-bold w-full text-right">Total Tax (B):</p>
                        <p class="text-text2 font-bold w-full text-right">{{taxesAmmount.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                    <div class="divider my-2"></div>
                    <div class="flex justify-between pt-2 px-4">
                        <p class="text-text2 font-bold w-full text-right">Total Tax (A + B):</p>
                        <p class="text-text2 font-bold w-full text-right">{{invObj.grandTotal.toFixed(2) | amountOnlyNumber}}</p>
                    </div>
                  </div>  
                </div>
                
                 <div class="sm:grid grid-cols-2 gap-4 mt-2">
                    <div class="mb-4">
                        <TextField
                        :inputId="'salecepersid'"
                        :textMaxlength="50"
                        :showcharLimit="true"
                        :inputext="invObj.salesPersonName"
                        :placholderText="`Sales Person Name`"
                        :lableText="'Sales Person'"
                        :autoFocus="false"
                        @inputChangeAction="(data) => invObj.salesPersonName = data"  />
                    </div>
                </div>
                 <div class="mb-4">
                  <div class="heading-5" v-if="!invoiceDesc">
                    <span @click="invoiceDesc = true" class="text-primary font-bold cursor-pointer">+ Invoice Notes</span>
                  </div>
                    <TextAreaInput v-if="invoiceDesc"
                      :inputId="'invicenoteid'"
                      :inputext="invObj.notes"
                      :placholderText="`Write notes here...`"
                      :lableText="'Invoice Notes'"
                      :inputType="'text'"
                      :autoFocus="false"
                      :textMaxlength="1000"
                      :showcharLimit="true"
                      :cols="50"
                      :rows="3"
                      :setReadOnly="false"
                      @inputChangeAction="(data) => invObj.notes = data"  />
                </div>
            </div>
            <div class="flex justify-end ">
            <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="createInvoice()"/>
            </div>
        </div>
        </div>
        
    </div>
    
    <div>
    </div>
    <AddCustomer v-if="addCustomer" />
    <ItemSelection :itemsSelected="invObj.selectedItemList" v-if="itemSelect" />
    <SelectedItmDetail  :selectedItemForDetail="selectedItemForDetail" v-if="itemSelectDetail" />
   </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
// import ItemDetail from './components/ItemDetail.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Dropdown from '@/View/components/dropdown.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import TextField from '@/View/components/textfield.vue'
import AddCustomer from '@/View/customer/components/AddCustomer.vue'
import ItemSelection from '@/View/setting/items/components/ItemSelection.vue'
import SelectedItmDetail from '@/View/setting/items/components/SelectedItmDetail.vue'
// import TaxBreakDown from './components/TaxBreakDown.vue'
import deboucneMixin from '@/mixins/debounce.js'
import moment from 'moment'
export default {
  name: "customer",
  components: {
    Button,
    SelectedItmDetail,
    TextAreaInput,
    ItemSelection,
    AddCustomer,
    TextField,
    DateTimePickerCustom,
    Dropdown,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      invoiceDesc: false,
        isDisable: false,
         cardHeight: 0,
         invoiceDatePickShow: false,
         invoiceDueDatePickShow: false,
         invObj: {
            invoiceId: 0,
            customerId: 0,
            invoiceNumber: '',
            invoiceStatusId: 0,
            subTotal: 0,
            totalTax: 0,
            grandTotal: 0,
            isDiscountBeforeTax: false,
            isDiscountOnValue: false,
            discountValue: 0,
            discountTotal: 0,
            invoiceDate: '',
            dueDate: '',
            notes: '',
            terms: '',
            billingFulladd: '',
            billingLine1: '',
            billingLine2: '',
            billingCity: '',
            billingState: '',
            billingZip: '',
            shippingFulladd: '',
            shippingLine1: '',
            shippingLine2: '',
            shippingCity: '',
            shippingState: '',
            shippingZip: '',
            salesPersonName: '',
            jobId: 0,
            selectedItemList: [],
            visitList: [],
         },
            customersList: [],
            addressList: [],
            taxesAmmount: 0,
            finalTaxList: [],
            searchForCustomer: '',
            searchApitext: '',
            addCustomer: false,
            itemSelect: false,
            itemSelectDetail: false,
            selectedItemForDetail: null,
            visitDetail: null,
         getCustList: () => {},
         errObj: {
          custErr: '',
          itemErr: '',
          dateError: ''
         }, 
         parentDetaiId: 0,
         requestFrom: ''
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  mounted() {
    if (this.$route.query.requestFrom) {
    this.requestFrom = this.$route.query.requestFrom
    }
    this.invObj.invoiceDate = moment(new Date()).format('YYYY-MM-DD')
    this.invObj.dueDate = moment(new Date()).format('YYYY-MM-DD')
    console.log("route", this.$route.query);
     this.getCustList = this.debounce(function () {
      this.getCustomerList()
    }, 500)
      this.getCustomerList(true)
    this.$root.$on("addCustomerHandler", (response, data) => {
      console.log(response);
      if (response) {
        this.selectNewCustomer(data)
      }
      this.addCustomer = false
    });
    this.$root.$on("removeSelectDetailHandler", (response, data) => {
      console.log(response, data);
      if (response) {
        let fIndex = this.invObj.selectedItemList.findIndex(x => x.itemMasterId === data.itemMasterId)
            if (fIndex >= 0) {
                this.invObj.selectedItemList.splice(fIndex, 1)
                
                setTimeout(() => {
                  this.taxesAmmount = 0
                  this.invObj.grandTotal = 0
                  this.finalTaxList = []

                  this.calculateTaxes(this.invObj.selectedItemList)
                }, 100);
            }
      }
      this.itemSelectDetail = false
    });
    this.$root.$on("itemSelectDetailHandler", (response, data) => {
      // console.log(response, data);
      if (response) {
        let fIndex = this.invObj.selectedItemList.findIndex(x => x.itemMasterId === data.itemMasterId)
            if (fIndex >= 0) {
                this.invObj.selectedItemList[fIndex].itemQty = data.itemQty
                this.invObj.selectedItemList[fIndex].itemPrice = data.itemPrice
                this.invObj.selectedItemList[fIndex].itemAmount = data.itemAmount
                this.invObj.selectedItemList[fIndex].taxRateList = data.taxRateList
                this.invObj.selectedItemList[fIndex].isTaxable = data.isTaxable
                this.invObj.selectedItemList[fIndex].itemTransactionId = data.itemTransactionId
                this.invObj.selectedItemList[fIndex].itemDescription = data.itemDescription
                
                setTimeout(() => {
                  this.taxesAmmount = 0
                  this.invObj.grandTotal = 0
                  this.finalTaxList = []

                  this.calculateTaxes(this.invObj.selectedItemList)
                }, 100);
            }
      }
      this.itemSelectDetail = false
    });
    this.$root.$on("itemSelectionHandler", (response, data) => {
      console.log(response, data);
      this.errObj.itemErr = ''
      if (response && data.length > 0 && this.itemSelect) {
        this.invObj.selectedItemList = data
        // data.map(item => {
        //   this.invObj.subTotal += item.itemPrice
        // })
        setTimeout(() => {
          this.taxesAmmount = 0
          this.invObj.grandTotal = 0
          this.finalTaxList = []

          this.calculateTaxes(data)
        }, 100);
      }
        this.itemSelect = false
        
    });
    if (parseInt(this.$route.query.invoiceId) > 0) {
      this.getInvoiceDetails ()
    }
    let detail = localStorage.getItem('detailData')
    console.log('detail=+++++++++++++++++++++++++++++++++++++++++++++++++++++', detail);
    if (localStorage.getItem('detailData') !== null && (this.requestFrom === 'job' || this.requestFrom === 'visit')) {
      this.visitDetail = JSON.parse(localStorage.getItem('detailData'))
    }
    if (this.visitDetail !== null) {
      this.invObj.visitList = []
      let Obj = {
        visitId: this.visitDetail.visitId,
        visitNumber: this.visitDetail.visitNumber,
        jobTitle: this.visitDetail.jobTitle,
        jobId: this.visitDetail.jobId,
      }
      this.invObj.visitList.push(Obj)
      if (this.requestFrom === 'job') {
        this.invObj.jobId = this.visitDetail.jobId
      }
      this.getVisitItemList (parseInt(this.visitDetail.visitId))
    }

  },
  methods: {
    updateDate () {
      if (new Date(this.invObj.dueDate) < new Date(this.invObj.invoiceDate)) {
        this.errObj.dateError = 'Due date should be greater than Invoice date'
      } else {
        this.errObj.dateError = ''
      }
    },
    createInvoice () {
      let isValid = true
      if (this.invObj.customerId === 0) {
        isValid = false
        this.errObj.custErr = 'Please select Customer'
      }
      if (this.invObj.selectedItemList.length === 0) {
        isValid = false
        this.errObj.itemErr = 'Please add at least one item'
      }
      if (this.errObj.dateError !== '') {
        isValid = false
      }
      if (isValid) {
        console.log('this.invObj==================================================================', this.invObj);
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.createInvoice(
          this.invObj,
          response => {
            localStorage.removeItem('visitDetail')
            this.$router.push({name: 'invoiceDetails', params: {invoiceId: response.Data}})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    showitemDetail (data) {
      this.selectedItemForDetail = data
        this.itemSelectDetail = true
    },
    selectInvItems () {
        this.itemSelect = true
    },
    addNewCustomer () {
        this.addCustomer = true
    },
    selectNewCustomer (data) {
        if (data !== null) {
          this.errObj.custErr = ''
            this.searchForCustomer = data.companyName
            this.invObj.customerId = data.customerId
            this.getCustDetails(data.customerId)
        } else {
            this.searchForCustomer = ''
            this.invObj.customerId = 0
        }
    },
    selectBilAddress (data) {
      this.invObj.billingFulladd = data !== null ? data.fullAddress : ''
      this.invObj.billingLine1 = data !== null ? data.addressLine1 : ''
      this.invObj.billingLine2 = data !== null ? data.addressLine2 : ''
      this.invObj.billingCity = data !== null ? data.city : ''
      this.invObj.billingState = data !== null ? data.state : ''
      this.invObj.billingZip = data !== null ? data.zip : ''
    },
    selectShipAddress (data) {
        this.invObj.shippingFulladd = data !== null ? data.fullAddress : ''
        this.invObj.shippingLine1 = data !== null ? data.addressLine1 : ''
        this.invObj.shippingLine2 = data !== null ? data.addressLine2 : ''
        this.invObj.shippingCity = data !== null ? data.city : ''
        this.invObj.shippingState = data !== null ? data.state : ''
        this.invObj.shippingZip = data !== null ? data.zip : ''
    },
    getCustDetails(custId) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.addressList = response.Data.addressList !== null ? response.Data.addressList : []
          let fIndex = this.addressList.findIndex(x => x.addressLine1 === this.invObj.billingLine1)
          if (fIndex >= 0) {
            this.invObj.billingFulladd = this.addressList[fIndex].fullAddress
          }
          let fIndex2 = this.addressList.findIndex(x => x.addressLine1 === this.invObj.shippingLine1)
          if (fIndex2 >= 0) {
            this.invObj.shippingFulladd = this.addressList[fIndex2].fullAddress
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    discard () {
        if (this.$route.query.fromCall === 'customer') {
          this.$router.push({name: 'customerDetails', params: {customerId: parseInt(this.$route.query.parentDetaiId)}, query: {activeTab: this.$route.query.activeTab}})
        } else if (this.$route.query.fromCall === 'invoiceList') {
          this.$router.push({name: 'invoice'})
        } else {
          this.$router.go(-1)
        }
    },
    callsearchCust (text) {
      this.searchApitext = text
     this.getCustList()
    },
    calculateTaxes (itemList) {
        this.invObj.subTotal = 0
        let list = itemList
        list.map((data) => {
            let itemAmount = data.itemAmount
            this.invObj.subTotal += itemAmount
            if (data.taxRateList !== null) {
              data.taxRateList.map((rate) => {
                let itemTaxamount = itemAmount * rate.taxValue / 100
                let fIndex = this.finalTaxList.findIndex(x => x.taxId === rate.taxId)
                if (fIndex >= 0) {
                    this.finalTaxList[fIndex].ammount += itemTaxamount
                } else {
                    let obj = {
                        taxName: rate.taxName,
                        taxValue: rate.taxValue,
                        ammount: itemTaxamount,
                        taxId: rate.taxId
                    }
                    this.finalTaxList.push(obj)
                }
            })
            }
            
        })
        this.finalTaxList.map(data=> {
            this.taxesAmmount += data.ammount
        })
        this.invObj.grandTotal = this.invObj.subTotal + this.taxesAmmount
    },
    getInvoiceDetails() {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.invoiceDetail(
        parseInt(this.$route.query.invoiceId),
        (response) => {
          this.invoiceDetail = response.Data;
          this.invObj.invoiceId = response.Data.invoiceId
          this.invObj.customerId = response.Data.customerId
          this.invObj.invoiceNumber = response.Data.invoiceNumber
          this.invObj.invoiceStatusId = response.Data.invoiceStatusId
          this.invObj.subTotal = response.Data.subTotal
          this.invObj.totalTax = response.Data.totalTax
          this.invObj.grandTotal = response.Data.grandTotal
          this.invObj.isDiscountBeforeTax = response.Data.isDiscountBeforeTax
          this.invObj.isDiscountOnValue = response.Data.isDiscountOnValue
          this.invObj.discountValue = response.Data.discountValue
          this.invObj.discountTotal = response.Data.discountTotal
          this.invObj.invoiceDate = moment(new Date(response.Data.invoiceDate)).format('YYYY-MM-DD')
          this.invObj.dueDate = moment(new Date(response.Data.dueDate)).format('YYYY-MM-DD')
          this.invObj.notes = response.Data.notes
          this.invObj.notes !== '' ? this.invoiceDesc = true : this.invoiceDesc = false 
          this.invObj.terms = response.Data.terms
          this.invObj.billingLine1 = response.Data.billingLine1
          this.invObj.billingLine2 = response.Data.billingLine2
          this.invObj.billingCity = response.Data.billingCity
          this.invObj.billingState = response.Data.billingState
          this.invObj.billingZip = response.Data.billingZip
          this.invObj.shippingLine1 = response.Data.shippingLine1
          this.invObj.shippingLine2 = response.Data.shippingLine2
          this.invObj.shippingCity = response.Data.shippingCity
          this.invObj.shippingState = response.Data.shippingState
          this.invObj.shippingZip = response.Data.shippingZip
          this.invObj.salesPersonName = response.Data.salesPersonName
          this.invObj.selectedItemList = response.Data.selectedItemList
          if (response.Data.selectedItemList.length > 0) {
           this.calculateTaxes(response.Data.selectedItemList) 
          }
          if (this.invObj.customerId > 0) {
            this.getCustDetails(this.invObj.customerId)
           let fIndex = this.customersList.findIndex(x => x.customerId === this.invObj.customerId)
              if (fIndex >= 0) {
                this.searchForCustomer = this.customersList[fIndex].companyName
              }
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getVisitItemList (visitId) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getVisitItemList(
        this.visitDetail.requestType === 'job' ? 0 : visitId,
        this.visitDetail.requestType === 'job' ? this.visitDetail.jobId : 0,
          response => {
            this.errObj.itemErr = ''
            if ( response.Data.tableRow !== null ) {
              this.invObj.selectedItemList = response.Data.tableRow
              setTimeout(() => {
                this.taxesAmmount = 0
                this.invObj.grandTotal = 0
                this.finalTaxList = []

                this.calculateTaxes(response.Data.tableRow)
              }, 100);
            }
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    getCustomerList (selectCust) {
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        'customer',
        response => {
          this.customersList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.customersList.map(data => {
            if (parseInt(this.$route.query.parentDetaiId) === data.customerId && selectCust) {
            this.getCustDetails(data.customerId)
            this.isDisable = true
            this.searchForCustomer = data.companyName
            this.invObj.customerId = data.customerId
            this.customerId = 0
            // this.$router.replace({'query': null});
          }
          })
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
  },
  beforeDestroy() {
    this.$root.$off("addCustomerHandler");
    this.$root.$off("itemSelectionHandler");
    this.$root.$off("itemSelectDetailHandler");
    this.$root.$off("removeSelectDetailHandler");
  },
};
</script>
<style scoped>

</style>